import React from "react"
import Layout from "../components/layout"

const AboutPage = () => (
  <Layout>
    <h2>Om oss</h2>
    <p>
      Vi startet opp i kristiansand i april 2004. Skolen driver opplæring i
      førerkortklasse B automat, B 96 og BE. Vi har trafikklærere med lang
      erfaring og er utdannet ved Statens Trafikklærerskole. Trafikklærerne
      underviser både på norsk og engelsk. Vårt mål er at du skal bli godt
      ivaretatt under opplæringen og bli en trygg sjåfør.
    </p>
    <br />
    <br />
    <h3>Vilkår</h3>
    <ul style={{ listStyleType: `none` }}>
      <li>
        En kjøretime varer i 45 minutter, fra du setter deg i bilen og til du
        forlater den. Timen omfatter både planlegging, tilbakemelding og
        bestilling av ny time. Dette gjør det hensiktsmessig å ha dobbelttimer.
      </li>
      <li>
        Eventuell avbestilling må skje snarest mulig og senest innen kl 12.00
        virkedagen før, hvis ikke vil timen bli fakturert i sin helhet.
      </li>
      <li>Ny time avtales med den enkelte lærer.</li>
      <li>
        Betaling skal skje løpende etter hver kjøretime/øvelse. Du kan velge å
        betale med faktura eller bankkort på skolens betalingsterminaler.
      </li>
      <li>Trafikklærerne har yrkesmessig taushetsplikt.</li>
    </ul>
    <br />
    <br />
    <br />
    Organisasjonsnummer:{" "}
    <a href={"https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=825296212"}>
      <b>825296212</b>
    </a>
    <br />
    <br />
  </Layout>
)

export default AboutPage
